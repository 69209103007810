import consumer from "./consumer"

consumer.subscriptions.create("DataChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
   // alert("connected test");
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    //alert("disconnected test");
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    //alert("received data");
    console.log(data)
  }
});


